import loadable from '@loadable/component';
import { PageLoading, categories, events } from "@tawn33y/gigsflow-core";

const Home = loadable(() => import('@tawn33y/gigsflow-core/dist/pages/Home/Home'), {
  resolveComponent: (components) => components.Home,
  fallback: <PageLoading />,
});

export default function HomePage() {
  return <Home events={events} categories={categories} />
}
